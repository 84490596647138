export default [
  'default',
  'bus',
  'car',
  'crane',
  'offroad',
  'person',
  'truck',
  'FEL',
  'ADT',
  'dumper',
  'dozer',
];
